import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { Notification } from '~/src/components/Typography';
import { Link } from '~/src/components/Link';
export const frontmatter = {
  title: 'Uploadcare Privacy Policy',
  description: 'Uploadcare Privacy Policy describes how we collect, manage, and use information, which may include personal data, you provide on our websites.',
  header: 'Privacy Policy',
  canonical: 'https://uploadcare.com/about/privacy-policy/',
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Notification mdxType="Notification">
  The previous version of our Privacy Policy can be
  found <Link to='/about/privacy_policy_20200117/' mdxType="Link">here</Link>.
    </Notification>
    <p>{`Effective Date: November 26, 2021`}</p>
    <p>{`This Privacy Policy applies to Uploadcare Inc, 2711 Centerville Road, Suite 400
City of Wilmington, County of New Castle, 19808, USA, registered under the laws
of USA under number 82-1639831 with the Internal Revenue Service of United
States of America.`}</p>
    <p>{`This Privacy Policy describes how Uploadcare collects and uses the information,
which may include personal information, you provide on our websites:
`}<a parentName="p" {...{
        "href": "/"
      }}>{`Uploadcare`}</a>{`, `}<a parentName="p" {...{
        "href": "https://pagedetox.com"
      }}>{`PageDetox`}</a>{` ('Sites'). The Sites are
jointly referred to as the 'Services.'`}</p>
    <p>{`This Privacy Policy applies to owners, employees, representatives or other
individuals acting on behalf of party to which Uploadcare provides the Services.
We act in our customers’ interest and are transparent about the processing of
any personal information. This policy also applies to our website visitors,
service inquiries, marketing leads, successful and unsuccessful Uploadcare job
applicants and our newsletter subscribers.`}</p>
    <p>{`“Personal information” refers to any information (data) relating to an
identifiable individual or their personal identity.`}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}><a parentName="h2" {...{
        "href": "#table-of-contents"
      }}>{`Table of contents`}</a></h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#consent"
        }}>{`Consent`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#data-collection"
        }}>{`Data collection`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#data-we-collect-on-sites-automatically"
        }}>{`Data we collect on sites automatically`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#personal-data-uploaded-by-you"
        }}>{`Personal data uploaded by you`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#data-processing-purposes"
        }}>{`Data processing purposes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#legal-bases-for-processing"
        }}>{`Legal bases for processing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#data-sharing"
        }}>{`Data sharing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#international-data-transfers"
        }}>{`International data transfers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#third-party-data"
        }}>{`Third party data`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#data-security"
        }}>{`Data security`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#data-retention"
        }}>{`Data retention`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#your-choices-and-rights"
        }}>{`Your choices and rights`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#privacy-policy-changes"
        }}>{`Privacy policy changes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#questions-and-complaints"
        }}>{`Questions and complaints`}</a></li>
    </ul>
    <h2 {...{
      "id": "consent"
    }}><a parentName="h2" {...{
        "href": "#consent"
      }}>{`Consent`}</a></h2>
    <p>{`By filling in a contact form on our Sites or scheduling a demo, you agree and
accept that we may gather, process, store and/or use the submitted personal
information under the rules set forth below.`}</p>
    <p>{`By giving your consent to us, you retain the right to have it withdrawn at any
time. You may also request that we delete any personal information we have about
you.`}</p>
    <h2 {...{
      "id": "data-collection"
    }}><a parentName="h2" {...{
        "href": "#data-collection"
      }}>{`Data collection`}</a></h2>
    <p>{`To grant you access and to use our Services, Uploadcare requests you to provide
the following information, some of which may be personal information:`}</p>
    <p><em parentName="p">{`Contact details:`}</em>{` We collect your contact information such as first name and
last name, business email address and address, job workplace and position,
telephone number.`}</p>
    <p><em parentName="p">{`Financial information:`}</em>{` Through third party payment processor
`}<a parentName="p" {...{
        "href": "https://stripe.com"
      }}>{`Stripe`}</a>{` we collect data necessary for payment, including
for invoicing purposes, such as your billing details and credit card number.`}</p>
    <p><em parentName="p">{`Communication:`}</em>{` When communicating with Uploadcare, Uploadcare collects and
processes written communications: email and live chat sessions, for improving
its Services and quality control, which includes the usage of the collected
communications for the handling of claims and fraud detection purposes.
Collected communications are kept for a limited amount of time and automatically
deleted, unless Uploadcare has a legitimate interest to keep such communications
for a longer period, including for fraud investigation and legal purposes.`}</p>
    <h2 {...{
      "id": "data-we-collect-on-sites-automatically"
    }}><a parentName="h2" {...{
        "href": "#data-we-collect-on-sites-automatically"
      }}>{`Data we collect on sites automatically`}</a></h2>
    <p>{`When using the Services, Uploadcare also collects information automatically,
some of which may be personal information.`}</p>
    <p>{`Examples of the information we collect and analyze include IP address, user
agent data (information about the browser type and version), device screen
resolution, device type and language, mouse events (movements, location, and
clicks), referring URLs and domains.`}</p>
    <p>{`We may also collect information about your Service activity, for example, your
log-in and log-out times, the duration of Service sessions, viewed web-pages, or
specific content on web-pages, activity measures, and geo-location.`}</p>
    <p>{`Uploadcare may also collect data automatically through cookies. For information
on how we use cookies, refer to `}<a parentName="p" {...{
        "href": "/about/cookie-policy/"
      }}>{`Cookie Policy`}</a>{`.`}</p>
    <h2 {...{
      "id": "personal-data-uploaded-by-you"
    }}><a parentName="h2" {...{
        "href": "#personal-data-uploaded-by-you"
      }}>{`Personal data uploaded by you`}</a></h2>
    <p>{`Whether from a cloud-based hosting service or your device, the content you
upload to the Services (data, text, graphic, audio, audio-visual files, etc.)
may include personal information. Note that the uploaded content may be
accessible to others (depending on your settings).`}</p>
    <p>{`We do not store information about who uploaded content, but in some cases it is
possible to find such a connection (depending on the state of current logs and
timeframe). Such data may include file UUID, client IP, user-agent, and
referrer.`}</p>
    <p>{`Please be careful when uploading the content and avoid any unintentional
disclosure of personal or sensitive information without the other person's
consent. If you process the personal data of End Users or other identifiable
individuals in your use of our Services, you are responsible for providing
legally adequate privacy notices and obtaining necessary consents for the
processing of such data. You represent to us that you have provided all
necessary privacy notices and obtained all necessary consents. You are
responsible for processing such data in accordance with applicable law.`}</p>
    <p>{`We do not modify your content in any way unless you requested such modification
from our Services.`}</p>
    <h2 {...{
      "id": "data-processing-purposes"
    }}><a parentName="h2" {...{
        "href": "#data-processing-purposes"
      }}>{`Data processing purposes`}</a></h2>
    <p>{`We use the information you provide, some of which may be personal information,
for the following purposes:`}</p>
    <p>{`A. Registration and account administration: We use the information to register
your property and to allow you and Uploadcare to administer and manage the
Services offered to you.`}</p>
    <p>{`B. Providing our Services: We use the information, which may include personal
information, to provide our Services, facilitate their performance, improve the
Services and Sites' content related to their usage.`}</p>
    <p>{`С. Analyze the use of Services: We use the information, which may include
personal information, to analyze the volume and history of your use of our
Services. We use the derivatives of such analyses to conduct monitoring and
reporting of your use of our Services to comply with our `}<a parentName="p" {...{
        "href": "/about/terms/"
      }}>{`Terms of
Service`}</a>{` ('ToS') and `}<a parentName="p" {...{
        "href": "/about/sla/"
      }}>{`Service Level Agreement`}</a>{` ('SLA').`}</p>
    <p>{`D. Customer Service: We use the information to provide you customer support and
customer success services, such as to: respond to your requests, questions and
concerns; provide you with best practices to use the Services; engage in
customer success communications when encountering unusual activities (e.g.,
spikes) in usage of Services.`}</p>
    <p>{`E. Marketing/Communications: We use the information to provide you with
information that you request, to send our newsletter, marketing communications
and updates about new products and services or other news or offers which we
believe will be of interest to you. We may invite you to attend events we
believe may be of interest to you. We may also use your personal information to
invite you to participate in referral programs. Where we use your personal
information for direct marketing purposes, such as newsletters and marketing
communications on new products and services or other offers which we believe
will be of interest to you, we include an unsubscribe link that you can use if
you don’t want us to send messages  of the same type in the future. Using an
unsubscribe link will be reflected on the `}<a parentName="p" {...{
        "href": "/accounts/settings/"
      }}>{`Account settings page`}</a>{` in
your account settings. We use the settings you provide on the “Subscriptions”
page to determine which types of messages you would like to receive. We can
introduce new types of messages without notice. If you have unchecked all of the
configurable types of messages on the “Subscriptions” page (all types except
“Administrative”), every new type of messages introduced will also be
automatically unchecked.`}</p>
    <p>{`F. Certain Services may offer you means to deliver media to your end-users,
which always includes Uploadcare as a source of that media. Uploadcare has
access to these media and uses automated systems to analyze them for security
purposes; fraud prevention; compliance with legal and regulatory requirements;
investigations of potential misconduct; product development and improvement;
research and customer or technical support.`}</p>
    <p>{`G. Analytics, improvements and research: We use the information which may
include personal information to conduct research and analysis. We may involve a
third party to do this on our behalf. We may share or disclose the results of
such research, including to third-parties and our affiliates, in anonymous,
aggregated form. We also use the information, which may include personal
information, to assess the needs of your business, to determine suitable
products and to improve our Services and marketing efforts. We may invite you to
take part in surveys, such as market research, and to analyze how to improve
your experience, and the functionality and quality of our Services.`}</p>
    <p>{`H. Security, fraud detection and prevention: We use the information, which may
include personal information, in order to prevent fraud and other illegal or
infringing activities when using our Sites as well as our Services. We also use
this information to investigate and detect fraud. Uploadcare can use personal
information for risk assessment and security purposes, including the
authentication of users. For these purposes, personal information may be shared
with third parties, such as law enforcement authorities as permitted by
applicable law and external advisors.`}</p>
    <p>{`I. Legal and compliance: In certain cases, Uploadcare needs to use the
information provided, which may include personal information, to handle and
resolve legal disputes or complaints, for regulatory investigations and
compliance, to enforce agreement(s) or to comply with lawful requests from law
enforcement insofar as it is required by law.`}</p>
    <p>{`If we use automated means to process personal information which produces legal
effects or significantly affects you, we will implement suitable measures to
safeguard your rights and freedoms, including the right to obtain human
intervention.`}</p>
    <h2 {...{
      "id": "legal-bases-for-processing"
    }}><a parentName="h2" {...{
        "href": "#legal-bases-for-processing"
      }}>{`Legal bases for processing`}</a></h2>
    <p>{`In view of purpose A to D, Uploadcare relies on the legal basis that the
processing of your personal information is necessary for the performance of your
agreement with Uploadcare. If you do not provide the requested information,
Uploadcare cannot register your property, allow you to use, administer, and
manage the Services, nor can we provide customer service to you.`}</p>
    <p>{`In view of purposes E to I, Uploadcare relies on its legitimate commercial
business interest to provide its Services to you, to prevent fraud and to
improve its Services. When using personal information to serve our commercial
business interest, Uploadcare will always balance your rights and interests in
the protection of your information against Uploadcare’s rights and interests.
For purpose I, Uploadcare relies also where applicable on compliance with legal
obligations (such as lawful law enforcement requests). Where needed under
applicable law, Uploadcare will obtain your consent prior to processing your
personal information for direct marketing purposes.`}</p>
    <p>{`If you wish to object to the processing set out under D to G and no opt-out
mechanism is available to you directly (for instance in your account settings),
to the extent applicable, please contact our Data Protection Officer at
`}{`[privacy@uploadcare.com][uc-email-privacy]`}{`.`}</p>
    <h2 {...{
      "id": "data-sharing"
    }}><a parentName="h2" {...{
        "href": "#data-sharing"
      }}>{`Data sharing`}</a></h2>
    <p>{`We share your information, which may include personal information, with third
parties as permitted by law and as described below. We do not sell or rent your
personal information.`}</p>
    <p>{`Service Providers: We share your information with third party service providers
to provide our Services, store data and/or maintain the Sites or conduct
business on our behalf. These service providers shall process personal
information only as instructed by and to provide the services to Uploadcare. For
the updated list of our service providers please view the `}<a parentName="p" {...{
        "href": "/about/sub-processors/"
      }}>{`sub-processors
list`}</a>{`.`}</p>
    <p>{`Payment Providers and other Financial Institutions: To process payments between
you and Uploadcare your information, as relevant, is shared with payment
providers and other financial institutions.`}</p>
    <p>{`Compelled Disclosure: When legally required, strictly necessary for the
performance of the Services, or to protect our rights, we disclose your
information to governmental authorities including law enforcement (subject to a
lawful request), or in legal proceedings. We will inform you before disclosing
the information required for this purpose. We do not disclose customer's content
unless we're required to do so to comply with the regulation or with a valid and
binding order of a governing body. We will object to overbroad or otherwise
inappropriate charges. Unless prohibited from doing so, or there is a clear
indication of illegal conduct connected with the use of Uploadcare Services, we
will notify customers before disclosing their content so they can seek
protection from disclosure. Uploadcare customers can encrypt their content and
have the option to manage their own encryption keys.`}</p>
    <p>{`Sharing and Disclosure of Aggregate Data: We may share information in aggregate
form and/or in a form which does not enable the recipient of such information to
identify you, with third parties, for example for industry and demographic
analysis.`}</p>
    <p>{`In addition, Uploadcare can disclose your personal information to third parties,
if you (or your account administrator acting on your behalf) requests or
authorizes disclosure thereof.`}</p>
    <h2 {...{
      "id": "international-data-transfers"
    }}><a parentName="h2" {...{
        "href": "#international-data-transfers"
      }}>{`International data transfers`}</a></h2>
    <p>{`The transmission of personal information as described in this Privacy Policy may
include overseas transfers of personal information to countries whose data
protection laws are not as comprehensive as those of the countries within the
European Economic Area (European Union, Island, Liechtenstein, Norway) or
Switzerland. Where required by European law, Uploadcare shall only transfer
personal information to recipients offering an adequate level of data
protection. In these situations, as may be required, we make contractual
arrangements to ensure that your personal information is still protected in line
with European standards. You can ask us to see a copy of these contractual
agreements by contacting our Data Protection Officer at
`}{`[privacy@uploadcare.com][uc-email-privacy]`}{`.`}</p>
    <p>{`Uploadcare has prepared a `}<a parentName="p" {...{
        "href": "/about/dpa/"
      }}>{`Data Processing Addendum`}</a>{` for its customers.
It forms a part of our `}<a parentName="p" {...{
        "href": "/about/terms/"
      }}>{`Terms of Service`}</a>{` ('ToS') and by accepting it
you are also accepting DPA. If you reside in the European Economic Area or
Switzerland, by signing this DPA you automatically accept Uploadcare Standard
Contractual Clauses (`}<a parentName="p" {...{
        "href": "https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX:32021D0914&locale=en"
      }}>{`Model Two`}</a>{`).`}</p>
    <h2 {...{
      "id": "third-party-data"
    }}><a parentName="h2" {...{
        "href": "#third-party-data"
      }}>{`Third party data`}</a></h2>
    <p>{`To provide its Services, Uploadcare captures and stores information, which may
include personal information, about you and your end-users uploading materials
to Uploadcare: (1) IP addresses; (2) request headers; (3) data submitted to the
API of our Services.`}</p>
    <p>{`Uploadcare, upon request from you or your account administrator acting on your
behalf, may use the data derived from media uploaded by your end-users to: (1)
detect MIME types; (2) deliver, convert or otherwise process media upon request
to our Services.`}</p>
    <p>{`You can recover, modify or delete your data from your Uploadcare account at any
time through the dedicated procedures provided within its Services.`}</p>
    <p>{`Uploadcare does not sell, share or rent out data stored in your Uploadcare
account to third parties, nor does it use them for any purposes other than those
outlined in this Privacy Policy.`}</p>
    <p>{`If you reside in the European Economic Area, UK or Switzerland and use our
Services you are considered the data controller within the meaning of the GDPR;
Uploadcare is acting as a data processor. This means that when you use our
Services (receiving data from your end-users) you are responsible in particular
for:`}</p>
    <ul>
      <li parentName="ul">{`Making all the declarations necessary to the relative data protection
authority.`}</li>
      <li parentName="ul">{`Complying with all current regulations in force.`}</li>
      <li parentName="ul">{`Obtaining the explicit consent of the individuals concerned when collecting
their personal information.`}</li>
      <li parentName="ul">{`Ensuring your authority to use the personal information collected in
accordance with the defined end purposes and refraining from any unauthorized
use.`}</li>
    </ul>
    <p>{`When using our Services to receive media from European Economic Area, UK or
Switzerland citizens, it is your obligation to ensure that your entire data
pipeline complies with the GDPR regulations. When using our Services to receive
media from citizens of the California state (USA), it is your obligation to
ensure that your entire data pipeline complies with the CCPA regulations.`}</p>
    <h2 {...{
      "id": "data-security"
    }}><a parentName="h2" {...{
        "href": "#data-security"
      }}>{`Data security`}</a></h2>
    <p>{`For purposes of keeping customer and end-user data ("Customer Data") safe and
secure, Uploadcare is committed to complying with industry-standard privacy and
security measures, as well as with all applicable data privacy and security laws
and regulations. This includes ensuring that Uploadcare's systems and
infrastructure are protected against unauthorized or accidental access, loss,
alteration, disclosure, or destruction. Uploadcare has taken all necessary
technical and operational measures to organize and protect its facilities,
hardware, and software, personnel, storage and networks, access controls,
monitoring and logging, vulnerability and breach detection, and incident
response measures.`}</p>
    <p>{`Read more in our `}<a parentName="p" {...{
        "href": "/about/security-whitepaper/"
      }}>{`security whitepaper`}</a>{`.`}</p>
    <h2 {...{
      "id": "data-retention"
    }}><a parentName="h2" {...{
        "href": "#data-retention"
      }}>{`Data retention`}</a></h2>
    <p>{`We will retain your information, which may include personal information, for as
long as it is necessary to enable you to use our Services, to provide our
Services to you, to comply with applicable laws, to resolve disputes with any
parties and otherwise as necessary to allow us to conduct our business,
including to detect and prevent fraud or other illegal activities. All personal
information we retain will be subject to this Privacy Policy.`}</p>
    <p>{`Uploadcare in no way undertakes to store all your data indefinitely. You can
access data so long as you hold an active account with us and for a period that
varies depending on the type of data concerned and the subscribed plan, but, in
no event no longer than 12 months after the closing of your account. The data
may be deleted at any time during active use of your account in accordance with
the provisions set forth above.`}</p>
    <p>{`If you have a question about a specific retention period for certain types of
personal information we process about you, please contact our Data Protection
Officer at `}{`[privacy@uploadcare.com][uc-email-privacy]`}{`.`}</p>
    <h2 {...{
      "id": "your-choices-and-rights"
    }}><a parentName="h2" {...{
        "href": "#your-choices-and-rights"
      }}>{`Your choices and rights`}</a></h2>
    <p>{`We want you to be in control of how your personal information is used by us. If
you reside in the European Economic Area, Switzerland or California the European
General Data Protection Regulation 2016/679 (GDPR), and the California Consumer
Privacy Act (CCPA), enables you to exercise your rights in the following ways:`}</p>
    <ul>
      <li parentName="ul">{`You can ask us for a copy of the personal information we hold about you.`}</li>
      <li parentName="ul">{`You have the right to know whether your personal information is sold or
disclosed and to whom.`}</li>
      <li parentName="ul">{`You have the right to say no to the sale of your personal information.`}</li>
      <li parentName="ul">{`You can inform us of any changes to your personal information, or you can ask
us to correct any of the personal information we hold about you. You are also
able at any time to modify your personal information by accessing your account
settings on our Sites.`}</li>
      <li parentName="ul">{`In certain situations, you can ask us to erase, block, or restrict the
processing of the personal information we hold about you or object to
particular ways in which we are using your personal information.`}</li>
      <li parentName="ul">{`In certain situations, you can also ask us to send the personal information
you have given us to a third party.`}</li>
    </ul>
    <p>{`Where we are using your personal information on the basis of your consent, you
are entitled to withdraw that consent at any time subject to applicable law.
Moreover, where we process your personal information based on legitimate
interest or the public interest, you have the right to object at any time to
that use of your personal information subject to applicable law.`}</p>
    <p>{`We rely on you to ensure that your personal information is complete, accurate,
and current. Please do inform us promptly of any changes to or inaccuracies of
your personal information by contacting
`}{`[privacy@uploadcare.com][uc-email-privacy]`}{`. Your applications will be processed
within 30 days. We may require your application to be accompanied by a photocopy
of proof of identity or authority. We may need to extend the time to process
your application by a further two months if the request is complex, or if we
receive a number of requests from the same individual. If this happens we will
notify you within the first 30 days.`}</p>
    <p>{`In addition, you have the right to lodge a complaint with the data protection
authority in your jurisdiction.`}</p>
    <h2 {...{
      "id": "privacy-policy-changes"
    }}><a parentName="h2" {...{
        "href": "#privacy-policy-changes"
      }}>{`Privacy policy changes`}</a></h2>
    <p>{`Just as our business changes constantly, this Privacy Policy may also change
from time to time. If you want to see changes made to this Privacy Policy from
time to time, we invite you to access this Privacy Policy to see the changes. If
we make material changes or changes that will have an impact on you (e.g. when
we start processing your personal information for other purposes than set out
above), we will contact you prior to commencing that processing.`}</p>
    <p>{`Any material changes made will be notified to you via our Sites or by email, to
the extent possible, three (3) business days at least before any changes come
into force.`}</p>
    <h2 {...{
      "id": "questions-and-complaints"
    }}><a parentName="h2" {...{
        "href": "#questions-and-complaints"
      }}>{`Questions and complaints`}</a></h2>
    <p>{`Please feel free to contact us if you have any questions or complaints about
Uploadcare’s Privacy Policy or practices. You may contact our Data Protection
Officer at `}{`[privacy@uploadcare.com][uc-email-privacy]`}{` or at our mailing address
below:`}</p>
    <p>{`18401 Collins Ave, Ste. 100-241, Sunny Isles Beach, FL, 33160 To: Elena
Sosnovskaya`}</p>
    <p>{`For EU data subjects, you can send your requests to:`}</p>
    <p>{`“Uploadcare,” CIC Rotterdam, Netherlands Groot Handelsgebouw Stationsplein 45,
A4.004 3013AK Rotterdam`}</p>
    <p>{`or to `}{`[privacy@uploadcare.com][uc-email-privacy]`}{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      